import React from "react";
import TaskManager from "../components/taskManager/manager";

const Home = () => {
  return (
    <div>
      <TaskManager />
    </div>
  );
};

export default Home;
